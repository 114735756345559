@import 'font.css';

html,
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    display: flex;
    width: 100%;
    min-width: 375px;
}
